import * as React from 'react';

import { Navigate } from 'react-router-dom';
import { createBrowserRouter } from 'react-router-dom';

import app from 'src/app';

import { LayoutMain } from 'src/layouts';

import { generatePath } from 'src/utils';

import { Header } from 'src/components';

import { RouteLeaderboard, RouteLeadrboardTable, RouteLeaderboardSchedule } from './leaderboard';
import { RouteProfile, RouteProfileAccount, RouteProfileHistory, RouteProfileActiveSeason } from './profile';
import { RouteRules } from './rules';
import { RouteFaq } from './faq';
import { RouteWallAuth } from './wall/auth';

import * as ROUTES from './routes';

export default createBrowserRouter(
	[
		{
			path: '/',
			element: <LayoutMain header={<Header user={app.models.user} />} />,
			loader: (data) => {
				return true;
			},
			children: [
				{
					path: ROUTES.ROOT,
					element: (
						<Navigate
							to={generatePath(ROUTES.LEADERBOARD_TABLE, {
								organizationId: ORGANIZATION_ID,
								resourceId: '',
							})}
							replace
						/>
					),
				},

				{
					path: ROUTES.LEADERBOARD,
					element: <RouteLeaderboard app={app} />,
					children: [
						{
							path: ROUTES.LEADERBOARD_TABLE,
							element: <RouteLeadrboardTable app={app} />,
						},
						{
							path: ROUTES.LEADERBOARD_SCHEDULE,
							element: <RouteLeaderboardSchedule app={app} />,
						},
					],
				},

				{
					path: ROUTES.PROFILE,
					element: <RouteProfile app={app} />,
					children: [
						{
							path: ROUTES.PROFILE_ACTIVE_SEASON,
							element: <RouteProfileActiveSeason app={app} />,
						},
						{
							path: ROUTES.PROFILE_HISTORY,
							element: <RouteProfileHistory app={app} />,
						},
						{
							path: ROUTES.PROFILE_ACCOUNT,
							element: <RouteProfileAccount app={app} />,
						},
					],
				},

				{
					path: ROUTES.RULES,
					element: <RouteRules app={app} />,
				},

				{
					path: ROUTES.FAQ,
					element: <RouteFaq app={app} />,
				},

				{
					path: ROUTES.WALL,
					children: [
						{
							path: ROUTES.WALL_AUTH,
							element: <RouteWallAuth user={app.models.user} />,
						},
					],
				},
			],
		},
	],
	{
		basename: ['/', PREFIX].join(''),
	}
);
