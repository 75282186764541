import * as React from 'react';
import { useIntl } from 'react-intl';

import _isNil from 'lodash/isNil';

import { ClassName } from 'src/lib';

import type { App, ICRMTextBlockResponse } from 'src/app';

import { Page, PageHead, PageBody, Heading, VideoPlayer, TextBlock, Throbber } from 'src/components';

import './intl';

export interface IRouteFaqPropTypes {
	app: App;
}

const cn = ClassName.create('route-faq', require('./style.less'));

export function RouteFaq(props: IRouteFaqPropTypes): React.ReactNode {
	const intl = useIntl();

	const [faq, setFaq] = React.useState<ICRMTextBlockResponse | null>(null);

	React.useEffect(() => {
		props.app.api.crm
			.request<ICRMTextBlockResponse>('/text-blocks/get-by-tbid/faq', {
				method: 'get',
			})

			.then((response) => {
				if (!!response?.error) {
					return;
				}

				setFaq(response);
			})
			.catch(console.error);
	}, []);

	return (
		<Page>
			<PageHead title={intl.formatMessage({ id: 'route.faq.page_title' })} />
			<PageBody>
				<div className={cn.get()}>
					<VideoPlayer src={require('./assets/description.webm')} />
					<Heading>Часто задаваемые вопросы</Heading>
					{!_isNil(faq) ? <TextBlock content={faq['content']} /> : <Throbber />}
				</div>
			</PageBody>
		</Page>
	);
}
