import * as React from 'react';
import { observer } from 'mobx-react';

import { ClassName } from 'src/lib';

import { type App } from 'src/app';

import { Page } from 'src/components';

export interface IRouteProfileHistoryPropTypes {
	app: App;
}

const cn = ClassName.create('route-profile-histroy', require('./style.less'));

export function RouteProfileHistoryComponent(
	props: IRouteProfileHistoryPropTypes
): React.ReactElement<IRouteProfileHistoryPropTypes> {
	return (
		<Page>
			<div className={cn.get()}>Histroy</div>
		</Page>
	);
}

export const RouteProfileHistory = observer(RouteProfileHistoryComponent);
