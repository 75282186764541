import * as React from 'react';
import { Formik, Form, Field } from 'formik';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';

import toast from 'react-hot-toast';

import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

import { ClassName } from 'src/lib';

import type { App, IWhereAmIResponseProfile, TUserSocialNetwork } from 'src/app';

import { PageHead, Heading, ActionTable, ActionTableRow, Status, Box, Button } from 'src/components';
import { ButtonSocialAuth } from 'src/components/button/social-auth';

import './intl';

export interface IRouteProfileAccountPropTypes {
	app: App;
}

const cn = ClassName.create('route-profile-account', require('./style.less'));

export function RouteProfileAccountComponent(
	props: IRouteProfileAccountPropTypes
): React.ReactElement<IRouteProfileAccountPropTypes> {
	const intl = useIntl();

	const { api } = props.app;
	const { user } = props.app.models;

	const [isDisabled, setIsDisabled] = React.useState(false);

	// @ts-ignore
	const validate = (values) => {
		const errors: { [key: string]: string } = {};

		const required: string[] = [
			'contacts_name_second',
			'contacts_name_first',
			'contacts_address_country',
			'contacts_address_index',
			'contacts_address_city',
			'contacts_address_value',
			'contacts_phone',
			'contacts_email',
			'contacts_telegram',
		];

		const formats: [string, RegExp][] = [
			['contacts_address_index', /[0-9]{6}/],
			['contacts_phone', /\+[0-9]{10,}/],
			['contacts_email', /.+@.+\..+/],
		];

		// Check formats
		formats.forEach(([name, regexp]) => {
			if (!regexp.test(values[name])) {
				errors[name] = 'wrong_format';
			}
		});

		// Check all required fields filled
		required.forEach((name) => {
			if (!values[name]) {
				errors[name] = 'empty';
			}
		});

		return errors;
	};

	// @ts-ignore
	const handleSubmit = async (values) => {
		const errors = validate(values);

		if (!_isEmpty(errors)) {
			Object.entries(errors)
				.reverse()
				.forEach(([name, error]) => {
					toast.error(
						intl.formatMessage(
							{ id: `route.profile.account.postal_address.form.error.${error}` },
							{
								field: intl.formatMessage({
									id: `route.profile.account.postal_address.field.label.${name}`,
								}),
								format: intl.formatMessage({
									id: `route.profile.account.postal_address.field.placeholder.${name}`,
								}),
							}
						)
					);
				});

			return Promise.reject(new Error('Some field has errors.'));
		}

		setIsDisabled(true);

		api.leaderboardLanding
			.request<IWhereAmIResponseProfile>('/UpdateMeta', {
				parameters: {
					Meta: values,
				},
			})
			.then((response) => {
				user.setContacts(response.Meta ?? {});

				toast.success(intl.formatMessage({ id: 'route.profile.account.postal_address.form.success' }));
			})
			.finally(() => {
				setIsDisabled(false);
			});
	};

	const formLayout = [
		[{ name: 'contacts_name_second' }, { name: 'contacts_name_first' }, { name: 'contacts_name_fathers' }],
		[{ name: 'contacts_address_country' }, { name: 'contacts_address_index' }, { name: 'contacts_address_city' }],
		[{ name: 'contacts_address_value' }],
		[{ name: 'contacts_phone' }, { name: 'contacts_email' }, { name: 'contacts_telegram' }],
	];

	return (
		<div className={cn.get()}>
			<PageHead title={intl.formatMessage({ id: 'route.profile.account.page_title' })} />
			<Heading level={5}>{intl.formatMessage({ id: 'route.profile.account.postal_address' })}</Heading>
			<Box isBackground>
				<div className={cn.get('contacts')}>
					<Formik
						initialValues={{
							...user.contacts,
							contacts_address_country:
								user.contacts.contacts_address_country ||
								intl.formatMessage({
									id: 'route.profile.account.postal_address.field.placeholder.contacts_address_country',
								}),
						}}
						onSubmit={handleSubmit}
					>
						<Form className={cn.get('contacts', 'form')}>
							{formLayout.map((row, index) => (
								<div key={`row-${index}`} className={cn.get('contacts', 'form', 'row')}>
									{row.map(({ name }) => (
										<Field key={name} name={name}>
											{/* @ts-ignore */}
											{({ field }) => (
												<div className={cn.get('contacts', 'form', 'row', 'field')}>
													<input
														id={field.name}
														className={cn.get('contacts', 'form', 'row', 'field', 'input')}
														placeholder={intl.formatMessage({
															id: `route.profile.account.postal_address.field.placeholder.${field.name}`,
														})}
														{...field}
														disabled={isDisabled}
													/>
													<label
														htmlFor={field.name}
														className={cn.get('contacts', 'form', 'row', 'field', 'label')}
													>
														{intl.formatMessage({
															id: `route.profile.account.postal_address.field.label.${field.name}`,
														})}
													</label>
												</div>
											)}
										</Field>
									))}
								</div>
							))}

							<div className={cn.get('contacts', 'form', 'actions')}>
								<Button Element="button" type="submit">
									{intl.formatMessage({ id: 'route.profile.account.postal_address.action.save' })}
								</Button>
							</div>
						</Form>
					</Formik>
				</div>
			</Box>

			<Heading level={5}>{intl.formatMessage({ id: 'route.profile.account.social_networks' })}</Heading>
			<Box isBackground>
				<div className={cn.get('social-networks')}>
					<ActionTable>
						{Object.entries(user.socialAuthProviders).map(([key, provider]) => {
							const label = intl.formatMessage({
								id: `route.profile.account.social_network.${key}`,
								defaultMessage: key,
							});

							const isConnected = !_isNil(user.connectedAccounts[key as TUserSocialNetwork]);

							return (
								<ActionTableRow
									key={key}
									status={<Status value={isConnected ? 'success' : 'idle'} />}
									action={
										isConnected ? (
											<span className={cn.get('social-networks', 'label')}>
												{intl.formatMessage({
													id: 'route.profile.account.social_network.action.connected',
												})}
											</span>
										) : (
											<ButtonSocialAuth
												provider={key}
												type={provider.type}
												link={provider.link}
												script={provider.script?.src}
												scriptData={provider.script?.data}
											>
												{intl.formatMessage(
													{
														id: 'route.profile.account.social_network.action.connect',
													},
													{
														network: label,
													}
												)}
											</ButtonSocialAuth>
										)
									}
								>
									{label}
								</ActionTableRow>
							);
						})}
					</ActionTable>
				</div>
			</Box>
		</div>
	);
}

export const RouteProfileAccount = observer(RouteProfileAccountComponent);
