import * as React from 'react';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';

import { ClassName } from 'src/lib';

import type { App } from 'src/app';

import { PageHead, Box, TextBlock } from 'src/components';

import './intl';

export interface IRouteLeaderboardSchedulePropTypes {
	app: App;
}

const cn = ClassName.create('route-leaderboard-schedule', require('./style.less'));

export function RouteLeaderboardScheduleComponent(
	props: IRouteLeaderboardSchedulePropTypes
): React.ReactElement<IRouteLeaderboardSchedulePropTypes> {
	const intl = useIntl();

	const { leaderboard, intl: intlModel } = props.app.models;

	return (
		<div className={cn.get()}>
			<PageHead
				title={[
					leaderboard.currentLeaderboard?.title,
					intl.formatMessage({ id: 'route.leaderboard.schedule.page_title' }),
				]}
			/>
			{(leaderboard.currentLeaderboard?.schedule ?? []).map((schedule, index) => {
				return (
					<div key={schedule.id} className={cn.get('block')}>
						<div className={cn.get('block', 'date')}>
							{[
								intl.formatMessage({ id: 'route.leaderboard.schedule.day' }, { number: index + 1 }),
								intlModel.formatDate(schedule.date, 'cccc'),
								intlModel.formatDate(schedule.date, 'd MMMM'),
							].join(' — ')}
						</div>
						<Box border="top" isBackground>
							<div className={cn.get('block', 'table')}>
								<div className={cn.get('block', 'table', 'header')}>
									<div className={cn.get('block', 'table', 'header', 'cell', ['activity'])}>
										{intl.formatMessage({ id: 'route.leaderboard.schedule.table.activity' })}
									</div>
									<div className={cn.get('block', 'table', 'header', 'cell', ['period'])}>
										{intl.formatMessage({ id: 'route.leaderboard.schedule.table.period' })}
									</div>
									<div className={cn.get('block', 'table', 'header', 'cell', ['description'])}>
										{intl.formatMessage({ id: 'route.leaderboard.schedule.table.description' })}
									</div>
								</div>
								<div className={cn.get('block', 'table', 'body')}>
									{schedule.items.map((item) => {
										return (
											<div key={item.id} className={cn.get('block', 'table', 'body', 'row')}>
												<div
													className={cn.get('block', 'table', 'body', 'row', 'cell', [
														'activity',
													])}
												>
													{item.title}
												</div>
												<div
													className={cn.get('block', 'table', 'body', 'row', 'cell', [
														'period',
													])}
												>
													{item.period}
												</div>
												<div
													className={cn.get('block', 'table', 'body', 'row', 'cell', [
														'description',
													])}
												>
													<TextBlock content={item.description} />
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</Box>
					</div>
				);
			})}
		</div>
	);
}

export const RouteLeaderboardSchedule = observer(RouteLeaderboardScheduleComponent);
