import * as React from 'react';
import { useIntl } from 'react-intl';

import _isNil from 'lodash/isNil';

import { ClassName } from 'src/lib';

import type { App, ICRMTextBlockResponse } from 'src/app';

import { Page, PageHead, PageBody, Heading, TextBlock, Throbber, Box } from 'src/components';

import './intl';

export interface IRouteRulesPropTypes {
	app: App;
}

const cn = ClassName.create('route-rules', require('./style.less'));

export function RouteRules(props: IRouteRulesPropTypes): React.ReactNode {
	const intl = useIntl();

	const [commonRules, setCommonRules] = React.useState<ICRMTextBlockResponse | null>(null);
	const [activitiesDescription, setActivitiesDescription] = React.useState<ICRMTextBlockResponse | null>(null);

	React.useEffect(() => {
		props.app.api.crm
			.request<ICRMTextBlockResponse>('/text-blocks/get-by-tbid/common-rules', {
				method: 'get',
			})

			.then((response) => {
				if (!!response?.error) {
					return;
				}

				setCommonRules(response);
			})
			.catch(console.error);

		props.app.api.crm
			.request<ICRMTextBlockResponse>('/text-blocks/get-by-tbid/activities-description', {
				method: 'get',
			})
			.then((response) => {
				if (!!response?.error) {
					return;
				}

				setActivitiesDescription(response);
			})
			.catch(console.error);
	}, []);

	return (
		<Page>
			<PageHead title={intl.formatMessage({ id: 'route.rules.page_title' })} />
			<PageBody>
				<div className={cn.get()}>
					<Heading>Правила конкурса</Heading>
					<Box isPadding isBackground border="top">
						{!_isNil(commonRules) ? <TextBlock content={commonRules['content']} /> : <Throbber />}
					</Box>
					<Heading>Описания интерактивов</Heading>
					{!_isNil(activitiesDescription) ? (
						<TextBlock content={activitiesDescription['content']} />
					) : (
						<Throbber />
					)}
				</div>
			</PageBody>
		</Page>
	);
}
