import * as React from 'react';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';

import { Subnavigation } from 'src/components';

import * as ROUTES from 'src/router/routes';

import './intl';

export interface IRouteProfileSubnavigationPropTypes {}

export function RouteProfileSubnavigation(
	props: IRouteProfileSubnavigationPropTypes
): React.ReactElement<IRouteProfileSubnavigationPropTypes> {
	const intl = useIntl();
	const params = useParams<{
		organizationId?: string;
		resourceId?: string;
	}>();

	return (
		<Subnavigation
			items={[
				{
					key: 'index',
					title: intl.formatMessage({ id: 'route.profile.subnavigation.index' }),
					path: ROUTES.PROFILE_ACTIVE_SEASON,
					parameters: params,
				},
				// {
				// 	key: 'history',
				// 	title: intl.formatMessage({ id: 'route.profile.subnavigation.history' }),
				// 	path: ROUTES.PROFILE_HISTORY,
				//	parameters: params,
				// },
				{
					key: 'account',
					title: intl.formatMessage({ id: 'route.profile.subnavigation.account' }),
					path: ROUTES.PROFILE_ACCOUNT,
					parameters: params,
				},
			]}
		/>
	);
}
