import * as React from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';

import _isNil from 'lodash/isNil';

import { ClassName } from 'src/lib';

import { PageHead, Leaderboard } from 'src/components';

import type { App } from 'app';

import * as ROUTES from 'src/router/routes';

import { generatePath } from 'src/utils';

import './intl';

export interface IRouteLeadrboardTablePropTypes {
	app: App;
}

const cn = ClassName.create('route-leaderboard-table', require('./style.less'));

export function RouteLeadrboardTableComponent(
	props: IRouteLeadrboardTablePropTypes
): React.ReactElement<IRouteLeadrboardTablePropTypes> {
	const { leaderboard, user } = props.app.models;

	const params = useParams<{
		organizationId: string;
		resourceId: string;
	}>();

	const navigate = useNavigate();
	const [search, setSearch] = useSearchParams({
		uid: '', // user id
		upl: 'twitch', // user platform
		// p: '1', // page
	});

	const intl = useIntl();

	React.useEffect(() => {
		if (!!params.resourceId) {
			return;
		}

		navigate(
			generatePath(ROUTES.LEADERBOARD_TABLE, {
				...leaderboard.currentLeaderboardId,
				organizationId: params.organizationId || leaderboard.currentLeaderboardId.organizationId,
			}),
			{ replace: true }
		);
	}, [params.resourceId]);

	React.useEffect(() => {
		search.set('uid', search.get('uid') || user.id);
		search.set('upl', search.get('upl') || 'twitch');

		setSearch(search);
	}, [search.get('uid'), search.get('upl') ?? 'twitch']);

	React.useEffect(() => {
		if (!params.resourceId) {
			return;
		}

		leaderboard
			.setCurrent({
				organizationId: params.organizationId,
				resourceId: params.resourceId,
			})
			.setRequestedUser({
				id: search.get('uid') as string,
				platform: search.get('upl') ?? 'twitch',
			})
			.loadParticipants()
			.then(() => {
				const requestedPage = !!search.get('p') ? parseInt(search.get('p') ?? '1', 10) : undefined;

				if (!requestedPage) {
					setPageToSearchParams(leaderboard.participantsPagination.current);
					return;
				}

				leaderboard.loadParticipants(requestedPage).then(() => {
					setPageToSearchParams(leaderboard.participantsPagination.current);
				});
			});
	}, [params.organizationId, params.resourceId, search.get('uid')]);

	const loadPage = React.useCallback(
		(page: number) => {
			leaderboard.loadParticipants(page).then(() => {
				setPageToSearchParams(leaderboard.participantsPagination.current);
			});
		},
		[leaderboard.participantsPagination.current]
	);

	const setPageToSearchParams = React.useCallback(
		(page: number) => {
			search.set('p', String(page));
			setSearch(search);
		},
		[search]
	);

	return (
		<div className={cn.get()}>
			<PageHead
				title={
					leaderboard.currentLeaderboard?.title ?? intl.formatMessage({ id: 'route.leaderboard.page_title' })
				}
			/>
			{
				!!leaderboard.participants.length ? (
					<Leaderboard leaderboard={leaderboard} setPage={loadPage} />
				) : null /* TODO: Add no data warning */
			}
		</div>
	);
}

export const RouteLeadrboardTable = observer(RouteLeadrboardTableComponent);
