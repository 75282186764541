import * as React from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';

import { ClassName } from 'src/lib';

import type {
	App,
	IBattlePassConditionSubscribe,
	IBattlePassConditionGoal,
	IWhereAmIResponseLeaderboard,
} from 'src/app';

import { PageHead, Heading, Box, ActionTable, ActionTableRow, Status, Button, Gift } from 'src/components';

import { ButtonSocialAuth } from 'src/components/button/social-auth';

import './intl';

export interface IRouteProfileActiveSeasonPropTypes {
	app: App;
}

const cn = ClassName.create('route-profile-active-season', require('./style.less'));

export function RouteProfileActiveSeasonComponent(
	props: IRouteProfileActiveSeasonPropTypes
): React.ReactElement<IRouteProfileActiveSeasonPropTypes> {
	const intl = useIntl();
	const [statusCheck, setStatusCheck] = React.useState<number[]>([]);

	const { leaderboard, user, intl: intlModel, battlePass, giveaways } = props.app.models;

	const giftsSquashLimit = 5;
	const [isGiftsSquashed, setIsGiftsSquashed] = React.useState(true);

	return (
		<>
			<PageHead title={intl.formatMessage({ id: 'route.profile.active_season.page_title' })} />
			<div className={cn.get()}>
				<Heading level={5}>{intl.formatMessage({ id: 'route.profile.active_season.statistics' })}</Heading>
				<div className={cn.get('statistics')}>
					<Box border="top" isBackground>
						<div className={cn.get('statistics', 'row')}>
							<div
								className={cn.get('statistics', 'row', 'image')}
								style={{ backgroundImage: `url(${user.profileImageUrl})` }}
							/>
							<div className={cn.get('statistics', 'row', 'data')}>
								<div className={cn.get('statistics', 'row', 'data', 'username')}>{user.username}</div>
								<div className={cn.get('statistics', 'row', 'data', 'values')}>
									{[
										{
											key: 'position',
											title: intl.formatMessage({
												id: 'route.profile.active_season.values.title.position',
											}),
											value: leaderboard.requestedUserParticipant?.position || 0,
											isPosition: true,
										},
										{
											key: 'total',
											title: intl.formatMessage({
												id: 'route.profile.active_season.values.title.total',
											}),
											value: user.history?.points.total || 0,
										},
										{
											key: 'watching',
											title: intl.formatMessage({
												id: 'route.profile.active_season.values.title.watching',
											}),
											value: user.history?.points.watching || 0,
										},
										{
											key: 'activities',
											title: intl.formatMessage({
												id: 'route.profile.active_season.values.title.activities',
											}),
											value: user.history?.points.activity || 0,
										},
									].map((data, index) => (
										<div
											key={`values-${data.key}`}
											className={cn.get('statistics', 'row', 'data', 'values', 'item')}
										>
											<div
												className={cn.get(
													'statistics',
													'row',
													'data',
													'values',
													'item',
													'title'
												)}
											>
												{data.title}
											</div>
											<div
												className={cn.get(
													'statistics',
													'row',
													'data',
													'values',
													'item',
													'number',
													[!!data.isPosition && 'position']
												)}
											>
												{intlModel.formatNumber(data.value ?? 0, '0,0')}
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
						{/* <div>
							<Button Element="button" arrowOnHover="bottom">
								More
							</Button>
						</div> */}
					</Box>
				</div>
				{battlePass.list.length > 0 && (
					<>
						<Heading level={5}>
							{intl.formatMessage({ id: 'route.profile.active_season.battle_pass' })}
						</Heading>
						<div className={cn.get('battle-pass')}>
							{battlePass.list.map((bp, bpIndex) => {
								const status = (() => {
									if (
										statusCheck.includes(bpIndex) ||
										bp.rewards.some((reward) => reward.status === 'waiting')
									) {
										return 'waiting';
									}

									if (bp.rewards.some((reward) => reward.status === 'idle')) {
										return 'idle';
									}

									return 'success';
								})();

								return (
									<Box key={`bp-${bpIndex}`} border="top" isBackground>
										<div className={cn.get('battle-pass', 'item')}>
											<div className={cn.get('battle-pass', 'item', 'conditions')}>
												<Heading level={6}>
													{intl.formatMessage({
														id: 'route.profile.active_season.battle_pass.title.conditions',
													})}
												</Heading>
												<ActionTable>
													{bp.conditions.map((condition, conditionIndex) => {
														const provider =
															condition.provider === 'youtube'
																? 'google'
																: condition.provider;

														return (
															<ActionTableRow
																key={[bpIndex, conditionIndex].join('-')}
																status={
																	<Status
																		value={
																			condition.status === 'success'
																				? condition.status
																				: status
																		}
																	/>
																}
																action={((type) => {
																	let link: string | undefined;

																	switch (type) {
																		case 'join':
																			if (condition.status === 'success') {
																				return (
																					<span
																						className={cn.get(
																							'battle-pass',
																							'status'
																						)}
																					>
																						{intl.formatMessage({
																							id: 'route.profile.active_season.battle_pass.action.joined',
																						})}
																					</span>
																				);
																			}

																			const p =
																				user.socialAuthProviders[
																					provider as
																						| 'twitch'
																						| 'telegram'
																						| 'steam'
																						| 'google'
																				];

																			if (!link) {
																				return null;
																			}

																			return (
																				<ButtonSocialAuth
																					provider={provider}
																					type={p?.type}
																					link={p?.link}
																					script={p?.script?.src}
																					scriptData={p?.script?.data}
																				>
																					{intl.formatMessage({
																						id: 'route.profile.active_season.battle_pass.action.join',
																					})}
																				</ButtonSocialAuth>
																			);

																		case 'subscribe':
																			if (condition.status === 'success') {
																				return (
																					<span
																						className={cn.get(
																							'battle-pass',
																							'status'
																						)}
																					>
																						{intl.formatMessage({
																							id: 'route.profile.active_season.battle_pass.action.subscribed',
																						})}
																					</span>
																				);
																			}

																			link = (
																				condition as IBattlePassConditionSubscribe
																			).providerLink;

																			if (!link) {
																				return null;
																			}

																			return (
																				<Button
																					Element="a"
																					to={link}
																					target="_blank"
																				>
																					{intl.formatMessage({
																						id: 'route.profile.active_season.battle_pass.action.subscribe',
																					})}
																				</Button>
																			);
																	}

																	return null;
																})(condition.type)}
															>
																{intl.formatMessage(
																	{
																		id: [
																			'route.profile.active_season.battle_pass.condition',
																			condition.type,
																		].join('.'),
																	},
																	{
																		provider: intl.formatMessage({
																			id: [
																				'route.profile.active_season.battle_pass.provider',
																				provider,
																			].join('.'),
																		}),
																		number: intlModel.formatNumber(
																			(condition as IBattlePassConditionGoal)
																				?.value ?? 0,
																			'0,0'
																		),
																	}
																)}
															</ActionTableRow>
														);
													})}
												</ActionTable>
											</div>
											<div className={cn.get('battle-pass', 'item', 'rewards')}>
												<Heading level={6}>
													{intl.formatMessage({
														id: 'route.profile.active_season.battle_pass.title.rewards',
													})}
												</Heading>
												<div className={cn.get('battle-pass', 'item', 'rewards', 'list')}>
													{bp.rewards
														.map((reward, rewardIndex) => {
															if (!reward.meta.title) {
																return null;
															}

															return (
																<div
																	key={reward.meta.giftid ?? `r-${rewardIndex}`}
																	className={cn.get(
																		'battle-pass',
																		'item',
																		'rewards',
																		'list',
																		'item'
																	)}
																>
																	{reward.meta.title}
																</div>
															);
														})
														.filter(Boolean)}
												</div>
											</div>
											<div className={cn.get('battle-pass', 'item', 'check', [status])}>
												<div className={cn.get('battle-pass', 'item', 'check', 'content')}>
													{((status) => {
														if (status === 'success') {
															return intl.formatMessage({
																id: 'route.profile.active_season.battle_pass.success',
															});
														}

														if (status === 'waiting') {
															return intl.formatMessage({
																id: 'route.profile.active_season.battle_pass.waiting',
															});
														}

														return (
															<Button
																Element="button"
																onClick={() => {
																	props.app.api.leaderboardLanding
																		.request<IWhereAmIResponseLeaderboard>(
																			'/CheckLeaderboardGiveaways',
																			{
																				parameters: {
																					ResourceID:
																						leaderboard.currentLeaderboardId
																							.resourceId,
																				},
																			}
																		)
																		.then((response) => {
																			battlePass.setData([response]);
																			setStatusCheck((value) => [
																				...value,
																				bpIndex,
																			]);
																		});
																}}
															>
																{intl.formatMessage({
																	id: 'route.profile.active_season.battle_pass.action.check',
																})}
															</Button>
														);
													})(status)}
												</div>
											</div>
										</div>
									</Box>
								);
							})}
						</div>
					</>
				)}
				{giveaways.items.length > 0 && (
					<>
						<Heading level={5}>{intl.formatMessage({ id: 'route.profile.active_season.gifts' })}</Heading>
						<div className={cn.get('giveaways')}>
							<div className={cn.get('giveaways', 'grid')}>
								{giveaways.items.slice(0, isGiftsSquashed ? 5 : undefined).map((giveaway, index) => (
									<Gift
										key={giveaway.id ?? `gw-${giveaway.itemId}-${index}`}
										itemId={giveaway.itemId}
										type={giveaway.type}
										title={giveaway.title}
										media={giveaway.media}
										code={giveaway.code}
									/>
								))}
							</div>
							{giveaways.items.length > giftsSquashLimit && isGiftsSquashed && (
								<div className={cn.get('giveaways', 'reveal')}>
									<Button Element="button" onClick={() => setIsGiftsSquashed(false)}>
										{intl.formatMessage(
											{ id: 'route.profile.active_season.giveaways.show_more' },
											{
												number: giveaways.items.length - giftsSquashLimit,
											}
										)}
									</Button>
								</div>
							)}
						</div>
					</>
				)}
			</div>
		</>
	);
}

export const RouteProfileActiveSeason = observer(RouteProfileActiveSeasonComponent);
