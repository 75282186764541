import * as React from 'react';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import { Outlet, useParams } from 'react-router-dom';

import { ClassName } from 'src/lib';

import { Page, PageBody, PageHead, GiftsCarousel, Heading, Subnavigation } from 'src/components';
import { LeaderboardSelector } from 'src/components/leaderboard/selector';

import type { App } from 'src/app';

import * as ROUTES from 'src/router/routes';

import './intl';

const cn = ClassName.create('route-leaderboard', require('./style.less'));

export interface IRouteLeaderboardPropTypes {
	app: App;
}

export function RouteLeaderboardComponent(props: IRouteLeaderboardPropTypes): React.ReactNode {
	const intl = useIntl();

	const params = useParams<{
		organizationId: string;
		resourceId: string;
	}>();

	const { leaderboard } = props.app.models;

	return (
		<Page>
			<PageHead title={intl.formatMessage({ id: 'route.leaderboard.page_title' })} />
			<PageBody>
				<div className={cn.get()}>
					<div className={cn.get('leaderboard-selector')}>
						<LeaderboardSelector leaderboard={leaderboard} />
					</div>
					{!!leaderboard.currentLeaderboardGifts.list.length && (
						<div className={cn.get('gifts')}>
							<Heading className={cn.get('gifts', 'title')} level={5}>
								{intl.formatMessage({ id: 'route.leaderboard.gifts_title' })}
							</Heading>
							<GiftsCarousel gifts={leaderboard.currentLeaderboardGifts} autoscroll={false} />
						</div>
					)}
					<div className={cn.get('subnavigation')}>
						<Subnavigation
							items={[
								{
									key: 'table',
									title: intl.formatMessage({ id: 'route.leaderboard.table.page_title' }),
									path: ROUTES.LEADERBOARD_TABLE,
									parameters: params,
								},
								{
									key: 'schedule',
									title: intl.formatMessage({ id: 'route.leaderboard.schedule.page_title' }),
									path: ROUTES.LEADERBOARD_SCHEDULE,
									parameters: params,
								},
								{
									key: 'rules',
									title: intl.formatMessage({ id: 'route.rules.page_title' }),
									path: ROUTES.RULES,
								},
								{
									key: 'faq',
									title: intl.formatMessage({ id: 'route.faq.page_title' }),
									path: ROUTES.FAQ,
								},
							]}
						/>
					</div>
					<div className={cn.get('content')}>
						<Outlet />
					</div>
				</div>
			</PageBody>
		</Page>
	);
}

export const RouteLeaderboard = observer(RouteLeaderboardComponent);

export * from './tabel';
export * from './schedule';
