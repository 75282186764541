import * as React from 'react';
import { Outlet, Navigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { App } from 'src/app';

import { ClassName } from 'src/lib';

import { Page, PageBody } from 'src/components';
import { LeaderboardSelector } from 'src/components/leaderboard/selector';

import { RouteProfileSubnavigation } from './components/subnavigation';

const cn = ClassName.create('route-profile', require('./style.less'));

export interface IRouteProfilePropTypes {
	app: App;
}

export function RouteProfileComponent(props: IRouteProfilePropTypes): React.ReactElement<IRouteProfilePropTypes> {
	const { leaderboard, user } = props.app.models;

	const params = useParams<{
		organizationId?: string;
		resourceId?: string;
	}>();

	React.useEffect(() => {
		if (!user.id) {
			return;
		}

		leaderboard
			.setCurrent({
				organizationId: params.organizationId ?? ORGANIZATION_ID,
				resourceId: params.resourceId,
			})
			.setRequestedUser({
				id: user.id,
				platform: 'twitch',
			})
			.loadParticipants();
	}, [params.organizationId, params.resourceId, user.id]);

	if (!user.isAuthorized) {
		return <Navigate to="/wall/auth" replace />;
	}

	return (
		<Page>
			<PageBody className={cn.get()}>
				<div className={cn.get('leaderboard-selector')}>
					<LeaderboardSelector leaderboard={leaderboard} />
				</div>
				<div className={cn.get('subnavigation')}>
					<RouteProfileSubnavigation />
				</div>
				<div className={cn.get('content')}>
					<Outlet />
				</div>
			</PageBody>
		</Page>
	);
}

export const RouteProfile = observer(RouteProfileComponent);

export * from './account';
export * from './active-season';
export * from './history';
