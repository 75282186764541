export default {
	page_title: 'Текущий сезон',
	statistics: 'Статистика',
	battle_pass: 'Задания сезона',
	gifts: 'Мои призы',
	'values.title.position': '#',
	'values.title.total': 'Всего очков',
	'values.title.watching': 'За просмотр',
	'values.title.activities': 'За активности',
	'battle_pass.title.conditions': 'Условия',
	'battle_pass.title.rewards': 'Награды',
	'battle_pass.provider.twitch': 'Twitch',
	'battle_pass.provider.telegram': 'Telegram',
	'battle_pass.provider.youtube': 'YouTube',
	'battle_pass.condition.join': 'Привязать {provider}',
	'battle_pass.condition.subscribe': 'Подписаться на канал в {provider}',
	'battle_pass.condition.goal': 'Получить {number} очков',
	'battle_pass.action.join': 'Привязать',
	'battle_pass.action.joined': 'Привязан',
	'battle_pass.action.subscribe': 'Подписаться',
	'battle_pass.action.subscribed': 'Подписан',
	'battle_pass.action.subscribe.check': 'Я подписан, проверьте',
	'battle_pass.action.check': 'Я выполнил все условия, проверьте',
	'battle_pass.waiting': 'Выполняется проверка',
	'battle_pass.success': 'Проверка пройдена',
	'giveaways.show_more':
		'Показать ещё {number, plural, one {{number} приз} few {{number} приза} other {{number} призов}}',
};
