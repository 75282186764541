import * as React from 'react';
import { Helmet } from 'react-helmet';

import { observer } from 'mobx-react';

import { BiLogoTwitch } from 'react-icons/bi';

import { ClassName } from 'src/lib';

import { UserModel } from 'src/app';

import { Page } from 'src/components';

const cn = ClassName.create('route-wall-auth', require('./style.less'));

export interface IRouteWallAuthPropTypes {
	user: UserModel;
}

export const RouteWallAuth = observer(function RouteWallAuthObserved(props: IRouteWallAuthPropTypes): React.ReactNode {
	return (
		<Page>
			<Helmet>
				<title>Paragon+ // Авторизация</title>
			</Helmet>
			<div className={cn.get()}>
				<div className={cn.get('wall')}>
					<div className={cn.get('wall', 'warning')}>
						<div className={cn.get('wall', 'warning', 'title')}>Привет, работяга!</div>
						<div className={cn.get('wall', 'warning', 'description')}>
							Чтобы получить свои призы, авторизуйся, пожалуйста,
							<br />
							через Twitch.
							<br />
							<br />
							<small>
								<em>
									На данный момент ты можешь забрать здесь
									<br />
									только Фрибеты от Winline.
								</em>
							</small>
						</div>
					</div>
					<a className={cn.get('wall', 'button')} href={props.user.authLinks.login}>
						<span className={cn.get('wall', 'button', 'icon')}>
							<BiLogoTwitch />
						</span>
						<span className={cn.get('wall', 'button', 'label')}>Войти через Twitch</span>
					</a>
				</div>
			</div>
		</Page>
	);
});
